@import '../../styles/customMediaQueries.css';

.cartIcon {
	position: relative;
	display: inline-block;
	@media (--viewportMedium) {
		color: #333;
	  }
}

.icon {
	width: 28px;
	height: 28px;
	stroke: currentColor;
	stroke-width: 2;
	fill: none;
}

.itemCount {
	position: absolute;
	top: -4px;
	right: -4px;
	background-color: var(--marketplaceColor);
	color: white;
	font-size: 12px;
	/* padding: 6px; */
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	@media (--viewportMedium) {
		background-color: #333;
	  }
}