@import '../../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 100%;
  height: auto;
  position: relative;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.accountLinksWrapper,
.customLinksWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.accountLinksWrapper {
  margin-bottom: 20px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 10px 0 0 0;
  & img{
    object-fit: cover;
  }
}

.greeting {
  /* Font */
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  padding: 3px 0 3px 0;
  margin-bottom: 1px;
  margin-top: 16px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
    padding: 5px 0 3px 0;
    /* x * 8px */
    margin-top: 24px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.customLinkFont {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 21px;
  line-height: 24px;

  padding: 4px 0 2px 0;
  /* x * 6px */
  margin-top: 24px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 1px 0 7px 0;
  }
}

.inbox {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  composes: customLinkFont;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;
  line-height: 30px;
  margin-bottom: 48px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  padding: 10px 10px 0px 10px;
  margin-top: 0rem;
  height: 42px;
  & svg {
    margin-left: 8px;
  }
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0;
  z-index: 1;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0px 0rem;
  height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background: #383f52;
    border-radius: 6px;
    outline: 1px solid #fff;
  }
}

.selectedOptionOpen {
  & svg {
    transform: rotate(176deg);
  }
}

.selectedOption {
  display: flex;
  gap: 10px;
}

.userCurrency {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.selectedOptionClose {
}

.activeOptions {
  background-color: #ddd;
}

.option {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
  padding: 8px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.dropDownWrapper {
  position: absolute;
  right: 0;
  top: 213px;
}

.cart {
  margin-bottom: 1rem;
}

.cartIconMobile {
}


.phoneNumber{
  text-decoration: none;
  white-space: nowrap;
  margin-bottom: 11px;
  &>span {
    color: var(--marketplaceColor) !important;
    font-weight: var(--fontWeightBold);
    font-size: 21px;
    line-height: 24px;
    padding: 4px 0 2px 0;
    margin-top: 0;
   
  }
}